<template>
  <router-view></router-view>
</template>

<script>

import { computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const route = useRoute()
    const name = computed(() => store.state.company.data.title);

    store.dispatch('company/reqBase', { company: route.params.companyID })
    store.commit('header/setTitle', name)

    onUnmounted(() => {
      store.commit('header/setTitle', '')
    })
    return {}
  }
}
</script>
